a {
  color: #fff;
}

.App {
  box-sizing: border-box;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'proxima-nova', sans-serif;
}

.navbar {
  text-align: center;
  margin-top: 20px;
  padding: 20px;
}

.sound-cards {
  padding: 20px;
  margin: auto;
  max-width: 600px;
  display: grid;
  grid-template-columns: repeat(4, 150px);
}

.sound-card {
  margin-bottom: 50px;
  width: 150px;
}

.imprint {
  text-align: center;
  color: #fff;
  margin-bottom: 80px;
  width: 75%;
  max-width: 600px;
}
.privacy-policy {
  overflow: hidden;
  color: #fff;
  margin-bottom: 80px;
  width: 75%;
  max-width: 600px;
}

.footer {
  padding: 20px;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: -50px;
  text-transform: uppercase;
  box-shadow: 0 80px 80px 20px rgba(0, 0, 0, 0.3);
}

.footer a {
  box-sizing: border-box;
  color: #fff;
  opacity: 0.5;
  text-decoration: none;
  border-bottom: 2px solid invisible;
  transition: opacity 0.3s;
}

.footer a:hover {
  opacity: 1;
}

a.active {
  opacity: 1;
  border-bottom: 2px solid #fff;
}

.footer * + * {
  margin-left: 15px;
}

@media only screen and (max-width: 620px) {
  .footer {
    flex-direction: column;
    align-items: center;
  }
  .footer * + * {
    margin-left: 0;
    margin-top: 10px;
  }

  .sound-cards {
    max-width: 300px;
    grid-template-columns: repeat(2, 150px);
  }

  .sound-card {
    width: 150px;
  }
}

@media only screen and (max-width: 400px) {
  .logo {
    width: 100px;
  }

  .sound-cards {
    max-width: 250px;
    grid-template-columns: repeat(2, 125px);
  }

  .sound-card {
    width: 125px;
  }
}
