html,
body {
  margin: 0;
  background: rgb(249, 194, 0);
  background: linear-gradient(
      0deg,
      rgba(249, 194, 0, 1) 40%,
      rgba(248, 170, 75, 1) 100%
    )
    no-repeat;
}
